import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CollegeService} from "../../shared/services/college.service";

@Component({
  selector: 'ps-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  colleges:any = [];
  private data: any;
  // tickets = [
  //   {
  //     id: '1',
  //     subjects: 'Second Date Phase for B.Sc. (Honour)',
  //     notification: 'Notification dated 10.08.2022 regarding addition for Second Phase date-Sheet for B.Sc. (Honours) Polymer Science & Food Technology Choice Based Credit System (CBCS-LOCF) Semester Examinations August-2022 meant for Part-3 (VI Semester) & Part-2 (IV Semester) students alongwith this only Ex-students, Essential Repeaters and Improvement cases of Part-1 (II Semester) shall also appear as per rule (Admission in 2019 & 2020)',
  //     status: 'In Process',
  //     created_at: '15/08/2022 01:39:24',
  //     updated_at: 'null',
  //     deleted_at: 'null'
  //   },
  //   {
  //     id: '2',
  //     subjects: 'Regarding Filling of examination forms ',
  //     notification: 'Notification dated 10.08.2022 regarding Filling of the examination forms for Compulsory Test in Hindi (CTH) for Choice Based Credit System (CBCS) for Under Graduate Semester Examinations August-2022',
  //     status: 'Resolved',
  //     created_at: '14/08/2022 13:39:12',
  //     updated_at: 'null',
  //     deleted_at: 'null'
  //   },
  //   {
  //     id: '7',
  //     subjects: 'Regarding GE Paper in Indian Economy I Exam',
  //     notification: 'Notification dated 05.08.2022 regarding additon of GE Paper in Indian Economy I Exams August-2022',
  //     status: 'Rejected',
  //     created_at: '15/08/2022 02:39:42',
  //     updated_at: 'null',
  //     deleted_at: 'null'
  //   },



  constructor(private router: Router,
              private modalService: NgbModal,
              private collegeService: CollegeService) {
  }

  ngOnInit(): void {
    this.refresh();
  }
  refresh(){
    this.getColleges();
  }
  getColleges(){
    this.collegeService.get(this.data).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.colleges = data['data'];
      }
    });
  }
abc(content:any){
  this.modalService.open(content,{size: 'lg'})
}
  goTo(url) {
    this.router.navigateByUrl(url);
  }
}
