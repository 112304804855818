import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import {UploadService} from './upload.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseService {
  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService,
              public fileUploadService: UploadService) {
    super(http, alertService, globalService);
  }

  /*updateProfile(id, data): Observable<any> {
    return this.putRequest(AppUrl.PROFILE_IMAGE(id), data);
  }*/
}
