<section id="notification-home">
  <ng-template #content>
    <div class="modal-header">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="text-box">
            <b>Add Notification</b>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-header">
      <div class="row">
<!--        <div class="card shadow-1px-light">-->
          <form class="theme-form">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="notification-for">Notification For</label>
                  <select id="notification-for" class="form-control" formControlName="notification-for">
                    <option value="">Select Type</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="select-college">Select College</label>
                  <select id="select-college" class="form-control" formControlName="select-college">
                    <option value="">Select College</option>
                    <option [value]="college.id" *ngFor = "let college of colleges">{{college?.name}}</option>
                  </select>
                </div>
              </div>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="textareaField">Notification</label>
                  <textarea id="textareaField" name="textareaField" placeholder="Enter Notification here"></textarea>
                </div>
              </div>
              <div class = "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class = "form-group">
                  <button class = "btn btn-orange">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
<!--    </div>-->
  </ng-template>

  <div class="row">
    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8">
      <div class="text-box">
        <b>Notifications</b>
      </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 2">
      <button class="btn btn-orange pull-right" (click)="goTo('notify/add')">Notifications List</button>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
      <button class="btn btn-orange pull-right" (click)="abc(content)">Add Notification</button>
    </div>
  </div>

  <div class = "row mt-50px">
    <div class="card card-details shadow-1px-light ">
      <h3 class = "ml-50px">Regarding Live Lectures For Calculas</h3>
      <p class = "ml-50px">Notification Live Lectures for Calcular II has been scheduled on 26 May 2024 at 1 PM. All students are requested to join it and after lectures all students can download notes also.</p>
      </div>
    <div class = "card card-details shadow-1px-light">
    </div>
    <div class = "card card-details shadow-1px-light">
    </div>
    <div class = "card card-details shadow-1px-light">
    </div>
    <div class = "card card-details shadow-1px-light">
    </div>
    </div>
</section>


<!--<div class="container-fluid">-->
<!--  <div class="row" *ngFor="let ticket of tickets; index as i">-->
<!--    <div class="col-sm-12 col-xl-12">-->
<!--      <div class="card ribbon-wrapper shadow-0 border p-0">-->
<!--        <div class="card-body notification-padding">-->
<!--          <div class="ribbon ribbon-success ribbon-vertical-left"><i class="fa fa-bell" *ngIf="ticket.status === 'Resolved'"></i></div>-->
<!--          <div class="ribbon ribbon-warning ribbon-vertical-left" *ngIf="ticket.status === 'In Process'"><i class="fa fa-bell"></i></div>-->
<!--          <div class="ribbon ribbon-secondary ribbon-vertical-left" *ngIf="ticket.status === 'Rejected'"><i class="fa fa-bell"></i></div>-->
<!--          <h4>{{ticket?.subjects}}</h4>-->
<!--          <h6>{{ticket?.notification}}</h6>-->
<!--          <div class="m-r-50 m-t-20 span badge badge-pill pill-badge-primary cursor-pointer pull-left">{{ticket?.created_at}}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="row">-->
<!--    <div class="col-sm-12 col-xl-12">-->
<!--      <div class="card  shadow-0 border ">-->
<!--        <div class="card-body">-->
<!--          <div class="row p-50px">-->
<!--            <div class="col-sm-12 col-xl-12 text-center">-->
<!--              <h6 class="mt-15px">No data to show</h6>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
