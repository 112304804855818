import {Injectable} from '@angular/core';
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "./alert.service";
import {GlobalService} from "./global.service";
import {Observable} from "rxjs";
import {AppUrl} from "../constants/app-url";

@Injectable({
  providedIn: 'root'
})
export class NotifyService extends BaseService {

  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }


  create(data): Observable<any> {
    return this.postRequest(AppUrl.NOTIFICATION(), data);
  }

  getData(data): Observable<any> {
    return this.getRequest(AppUrl.NOTIFICATION(), data);
  }


  update(id, data): Observable<any> {
    return this.putRequest(AppUrl.NOTIFICATION(id), data);
  }
  detail(id): Observable<any> {
    return this.getRequest(AppUrl.NOTIFICATION(id));
  }

  getStatus(id, data): Observable<any> {
    return this.putRequest(AppUrl.NOTIFICATION_STATUS(id), data);
  }

}
