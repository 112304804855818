import { Component, OnInit } from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StorageService} from '../../shared/services/storage.service';
import {FileService} from '../../shared/services/file.service';
import {AlertService} from '../../shared/services/alert.service';
import {NgbModal, NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';
import {ProfileService} from '../../shared/services/profile.service';
import {DataService} from '../../shared/services/data.service';

@Component({
  selector: 'ps-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  password_icon: boolean = false;
  addProfileForm: FormGroup;
  imageChangedEvent: any;
  croppedImage: any;
  croppedImageEvent: any;
  profileImageUploadArea: any = 'assets/images/image-backgrounds/200-200.png';
  profileImage: any

  constructor(config: NgbPaginationConfig,
              private modalService: NgbModal,
              private alertService: AlertService,
              private fileService: FileService,
              private profileService: ProfileService,
              private dataService: DataService,
              private fb: FormBuilder) {
    config.size = 'sm';
    config.boundaryLinks = true;
  }

  modalType: any;
  user: any;

  ngOnInit(): void {
    this.user = StorageService.getItem('self');
    this.refresh();
  }

  refresh() {
    this.addProfileForm = this.fb.group({
      name: [this.user.name, Validators.required],
      code: [this.user.username, Validators.required],
      username: [this.user.username, Validators.required],
      email: [this.user.email, [Validators.required, Validators.email]],
      contact: [this.user.contact, Validators.required],
    });

  }

  update() {
    this.alertService.showSuccess({title: 'Success', message: 'Profile updated successfully'});
  }

  openLg(content) {
    this.modalType = 'PASSWORD';
    this.modalService.open(content, {size: 'lg'});
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.croppedImageEvent = event;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  onImageChange(e) {
    this.imageChangedEvent = e;
  }

  openProfileModel(content) {
    this.modalType = 'PROFILE_IMAGE';
    this.modalService.open(content);
  }


}
