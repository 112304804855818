import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {AppUrl} from "../constants/app-url";
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "./alert.service";
import {GlobalService} from "./global.service";

@Injectable({
  providedIn: 'root'
})
export class CollegeService extends BaseService {
  private uniqueId: string;

  constructor(
    public http: HttpClient,
    public alertService: AlertService,
    public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  get(data): Observable<any> {
    return this.getRequest(AppUrl.COLLEGES(), data);
  }

  add(data): Observable<any> {
    return this.postRequest(AppUrl.COLLEGES(), data);
  }
  getStatus(id, data): Observable<any> {
    return this.putRequest(AppUrl.COLLEGE_STATUS(id), data);
  }
  update(updateId, data): Observable<any> {
    return this.putRequest(AppUrl.COLLEGES(updateId), data);
  }
  delete(id:number):Observable<any> {
    return this.deleteRequest(AppUrl.REFERRED_BY(id));
  }
}



