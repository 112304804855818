import {Routes} from '@angular/router';

export const content: Routes = [
  {
    path: 'general',
    loadChildren: () => import('../../components/general/general.module').then(m => m.GeneralModule)
  },
  {
    path: 'college',
    loadChildren: () => import('../../components/colleges/colleges.module').then(m => m.CollegesModule)
  },
  {
    path: 'notify',
    loadChildren: () => import('../../components/notification/notification.module').then(m => m.NotificationModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('../../components/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'sessions',
    loadChildren: () => import('../../components/sessions/sessions.module').then(m => m.SessionsModule)
  },
  {
    path: 'modules',
    loadChildren: () => import('../../components/modules/modules.module').then(m => m.ModulesModule)
  },
  {
    path: 'students',
    loadChildren: () => import('../../components/students/students.module').then(m => m.StudentsModule)
  },
  {
    path: 'accounts',
    loadChildren: () => import('../../components/accounts/accounts.module').then(m => m.AccountsModule)
  },
  {
    path: 'documents',
    loadChildren: () => import('../../components/documents/documents.module').then(m => m.DocumentsModule)
  },
  {
    path: 'support',
    loadChildren: () => import('../../components/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'leads',
    loadChildren: () => import('../../components/leads/leads.module').then(m => m.LeadsModule)
  }

];
