import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'ps-colleges',
  templateUrl: './colleges.component.html',
  styleUrls: ['./colleges.component.scss']
})
export class CollegesComponent implements OnInit {
  submitted: any = false;

  constructor(private router: Router,) {
  }

  ngOnInit(): void {

  }



  goTo(url) {
    this.router.navigateByUrl(url);
  }
}
