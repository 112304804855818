<section id="student-home">
  <div class="row">
    <div class=" col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
      <div class="Box">
        <div class="text-box">
          <b>All Students Report</b>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 w-220px h-48px">
      <select id="Smt Leela Devi College" class="form-control w-196px h-48px" name="Smt Leela Devi College" (change)=getSessions($event.target.value)>
        <option value="" disabled selected>Select College</option>
        <option [value]="college.id" *ngFor="let college of colleges"> {{college?.name}}</option>
      </select>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 w-220px h-48px">
      <select id="2022-23" class="form-control w-196px h-48px" name="2022-23">
        <option value="" disabled selected>Select Session</option>
        <option value="session.id" *ngFor="let session of sessions"> {{session?.session_year}}</option>
      </select>
    </div>
  </div>

  <div class="row mt-50px">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <div class="card m-50px card-size">
              <div class="row">
                <div class="col-12">
                  <h3 class="text-bold pl-20px">Total Students</h3>
                  <h4 class="color-orange text-bold pl-20px">729</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-50px">
    <div class=" col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
      <div class="Box">
        <div class="text-box f-m">
          <b>Students Report by Course</b>
        </div>
      </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
      <select id="smt-leela-devi-college" class="form-control w-220px h-48px" name="Smt Leela Devi College">
        <option value="" disabled selected>Smt Leela Devi College</option>
        <option value=" "></option>
      </select>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
      <select id="2023-24" class="form-control w-220px h-48px" name="2023-24">
        <option value="" disabled selected>2023-24</option>
        <option value=" "></option>
      </select>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
      <select id="B.A." class="form-control w-220px h-48px" name="B.A.">
        <option value="" disabled selected>B.A.</option>
        <option value=" "></option>
      </select>
    </div>
  </div>

  <div class="row mt-50px">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card card-border">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <div class="card m-50px  card-size">
              <div class="row">
                <div class="col-12">
                  <h3 class="text-bold pl-20px">Total Students</h3>
                  <h4 class="color-orange text-bold pl-20px">370</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
