import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppUrl {

  static get ADMIN_URL(): string {
    return environment.appUrl + 'admin/';
  }

  static get UNIVERSITY_URL(): string {
    return environment.appUrl + 'university/';
  }

  static STATES(): string {
    return AppUrl.ADMIN_URL + 'states';
  }

  static COUNTRIES(): string {
    return AppUrl.ADMIN_URL + 'countries';
  }

  static get COLLEGE_URL(): string {
    return environment.appUrl + 'admin/';
  }

  static get LOGIN(): string {
    return AppUrl.ADMIN_URL + 'login';
  }

  static get SELF(): string {
    return AppUrl.ADMIN_URL + 'self';
  }

  static UPDATE_SELF(): string {
    return AppUrl.ADMIN_URL + 'update-self';
  }

  static COLLEGES_COUNT(): string {
    return AppUrl.COLLEGE_URL + 'college-count';
  }

  static LOGOUT(): string {
    return AppUrl.ADMIN_URL + 'logout';
  }

  static SUB_PERMISSIONS(unique_id?): string {
    if (unique_id) {
      return AppUrl.COLLEGE_URL + 'sub-permission/' + unique_id;
    } else {
      return AppUrl.COLLEGE_URL + 'sub-permission';
    }
  }

  static COLLEGES(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 'university/' + uniqueId;
    } else {
      return AppUrl.COLLEGE_URL + 'university';
    }
  }
  static REFERRED_BY(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 'university/' + uniqueId;
    } else {
      return AppUrl.COLLEGE_URL + 'university';
    }
  }
  static COURSE(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 'course/' + uniqueId;
    } else {
      return AppUrl.COLLEGE_URL + 'course';
    }
  }
  static COURSE_DELETE(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 'course/' + uniqueId;
    } else {
      return AppUrl.COLLEGE_URL + 'course';
    }
  }
  static BRANCH_DELETE(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 'branch/' + uniqueId;
    } else {
      return AppUrl.COLLEGE_URL + 'branch';
    }
  }
  static COURSE_COUNT(): string {
    return AppUrl.COLLEGE_URL + 'course-count';
  }
  static PROGRAM_COUNT(): string {
    return AppUrl.COLLEGE_URL + 'program-count';
  }
  static  BRANCH_COUNT(): string {
    return AppUrl.COLLEGE_URL + 'branch-count';
  }
  static PROGRAM_DELETE(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 'program/' + uniqueId;
    } else {
      return AppUrl.COLLEGE_URL + 'program';
    }
  }
  static PROGRAMS(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 'program/' + uniqueId;
    } else {
      return AppUrl.COLLEGE_URL + 'program';
    }
  }
  static BRANCH(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'branch/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'branch';
    }
  }
  static BRANCH_UPDATE(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'college-group/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'college-group';
    }
  }
  static STUDENTS(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 'centre' + uniqueId;
    } else {
      return AppUrl.COLLEGE_URL + 'student';
    }
  }
  static SESSIONS(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 'session/' + uniqueId;
    } else {
      return AppUrl.COLLEGE_URL + 'session';
    }
  }
  static SUBJECTS(uniqueId?): string {
    if(uniqueId){
      return AppUrl.COLLEGE_URL + 'subject/' + uniqueId;
    }else {
      return AppUrl.COLLEGE_URL + 'subject';
    }
  }
  static NOTIFICATION(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'notify/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'notify';
    }
  }
  static NOTIFICATION_STATUS(id?): string {
    return AppUrl.COLLEGE_URL + 'notify-status/' + id;
  }


  static COLLEGEBYUSER(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'university/' + id;
    }
  }
  static COLLEGE_STATUS(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 'university-status/' + uniqueId;
    }
  }
  static PROGRAM_STATUS(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 'program-status/' + uniqueId;
    }
  }
  static COURSE_STATUS(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 'course-status/' + uniqueId;
    }
  }
  static BRANCH_STATUS(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 'branch-status/' + uniqueId;
    }
  }

  static TICKETS(): string {
    return AppUrl.COLLEGE_URL + 'support-ticket';
  }

  static TICKET_COUNT(): string {
    return AppUrl.COLLEGE_URL + 'support-ticket-report-count';
  }

  static TICKET_STATUS(unique_id): string {
    return AppUrl.COLLEGE_URL + 'support-ticket-status/' + unique_id;
  }


}


