<section id="colleges-home">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <div class="text-box">
          <b>Enter College Details</b>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <button class="btn btn-primary pull-right btn-orange"
                (click)="goTo('college/list-college')">View All <i class="fa fa-external-link"></i>
        </button>
      </div>
    </div>
    <div class="row mt-50px">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card  bx-shadow">
          <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div class="card m-50px card-size">
                <div class="row">
                  <div class="col-12">
                    <i class="fa-solid fa-code-branch f_32 p-40px"></i>
                    <h3 class="text-bold pl-20px">Total Colleges</h3>
                    <h4 class="color-orange text-bold pl-20px">4</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

