import {Component, OnInit} from '@angular/core';
import {NgbModal, NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from "@angular/router";
import {NotifyService} from "../../../shared/services/notify.service";
import {CollegeService} from "../../../shared/services/college.service";


@Component({
  selector: 'ps-add-notification',
  templateUrl: './add-notification.component.html',
  styleUrls: ['./add-notification.component.scss']
})
export class AddNotificationComponent implements OnInit {
  searchForm: FormGroup;
  colleges: any = [];
  collegeID: any;
  universityID: any;
  collegeUpadteId: any;
  notification: any = [];
  notifications: any = [];
  notified_for: any = [];
  addNotificationForm: FormGroup;

  pagination = {
    page: 0,
    total: null,
    perpage: 5,
    pages: null
  };

  advancePage = 1;
  currentPage = 3;
  isDisabled = true;
  closeResult: string;
  submitted: any = false;
  collegeForm: FormGroup;
  selectedData: any;
  private data: any;

  constructor(config: NgbPaginationConfig,
              private modalService: NgbModal,
              private fb: FormBuilder,
              private router: Router,
              private collegeService: CollegeService,
              private notificationService: NotifyService) {
    config.size = 'lg';
    config.boundaryLinks = true;
  }

  ngOnInit() {
    this.refresh();
  }


  refresh() {
    this.collegeForm = this.fb.group({
      'college_id': ['', Validators.required]
    });
    this.addNotificationForm = this.fb.group({
      notified_for: ['', Validators.required],
      notification: ['', Validators.required]
    });
    /* this.addNotificationForm.get('college_id').valueChanges.subscribe((value) => {
       this.collegeID = value;
     });*/
    this.collegeForm.get('college_id').valueChanges.subscribe((value) => {
      this.collegeID = value;

    });
    this.getColleges();
    this.getnotification();
  }

  /*getnotification() {
    let params = {
      'university_id' : 3
    }
     this.notificationService.getData(params).subscribe( data => {
      if (data && data['data'].length > 0) {
        console.log(data)
        this.notifications = data['data'];
      }
       this.pagination = {
         page: data.current_page,
         total: data.total,
         perpage: data.per_page,
         pages: data.last_page,
       };
    });

  }*/

  getnotification(params: any = {}) {
      /*let params = {
        'university_id': 3
      }*/
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.notifications = [];
    this.notificationService.getData(params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.notifications = data['data'];
      }
      this.pagination = {
        page: data.current_page,
        total: data.total,
        perpage: data.per_page,
        pages: data.last_page,
      };
      console.log('pagination data' , this.pagination);

    });
  }
  onPageChange(page) {
    this.pagination.page = page;
    const params = {
      'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
      'username': (this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
      'email': (this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
      'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : '',
      'state_id': (this.searchForm.value && this.searchForm.value.state_id) ? this.searchForm.value.state_id : ''
    };
    this.getnotification(params);
  }

  getColleges() {
    this.collegeService.get(this.data).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.colleges = data['data'];
      }
    });
  }

  changeStatus(data, status) {
    this.submitted = true;
    if (this.submitted) {
      this.notificationService.getStatus(data.id, {'status': status}).subscribe(data => {
        this.submitted = false;
        this.getnotification();
      }, error => {
        this.submitted = false;
      });
    }
  }

  get(params: any = {}) {
    this.notifications = [];
    params.college_id = this.collegeID;
    this.notificationService.detail(params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.notifications = data['data'];
      }
      this.pagination = {
        page: data.current_page,
        total: data.total,
        perpage: data.per_page,
        pages: data.last_page,
      };
    });
  }


  submit() {

    this.submitted = true;
    if (!this.addNotificationForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'notification': (this.addNotificationForm && this.addNotificationForm.getRawValue() && this.addNotificationForm.getRawValue().notification) ? this.addNotificationForm.getRawValue().notification : '',
        'notified_for': (this.addNotificationForm && this.addNotificationForm.getRawValue() && this.addNotificationForm.getRawValue().notified_for) ? this.addNotificationForm.getRawValue().notified_for : '',
      };
      console.log(params);
      this.notificationService.create(params).subscribe(data => {
        this.submitted = false;
        this.modalService.dismissAll();
        this.addNotificationForm.patchValue({
          'notification': '',
          'notified_for': '',
        });
      }, error => {
        this.submitted = false;
      });
    }


  }


  update() {
    // this.modalType = 'UPDATE';
    this.submitted = true;
    if (!this.addNotificationForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'notification': (this.addNotificationForm && this.addNotificationForm.getRawValue() && this.addNotificationForm.getRawValue().notification) ? this.addNotificationForm.getRawValue().notification : '',
        'notified_for': (this.addNotificationForm && this.addNotificationForm.getRawValue() && this.addNotificationForm.getRawValue().notified_for) ? this.addNotificationForm.getRawValue().notified_for : '',
      };
      this.notificationService.update(this.selectedData.id, params).subscribe(data => {
        this.submitted = false;
        this.modalService.dismissAll('');
        this.resetForm();
        this.selectedData = undefined;
        this.getnotification();
      }, error => {
        this.submitted = false;
      });
    }
  }


  openLg(content) {
    this.modalService.open(content, {size: 'lg'});
  }


  openEditModal(content, data) {
    this.resetForm();
    this.modalService.open(content, {size: 'md'});
    this.selectedData = data;
    this.addNotificationForm.patchValue({
      'notification': data.notification,
      'notified_for': data.notified_for,
      'id': this.selectedData.id,
    });
  }

  resetForm() {
    this.addNotificationForm.patchValue({
      'notification': '',
      'college_id': '',
    });
    this.selectedData = undefined;
  }


}
