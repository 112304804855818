import { Component, OnInit } from '@angular/core';
import {CollegeService} from "../../shared/services/college.service";
import {SessionService} from "../../shared/services/session.service";
import {StudentService} from "../../shared/services/student.service";

@Component({
  selector: 'ps-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss']
})
export class StudentsComponent implements OnInit {
  colleges: any;
  data: any;
  sessions: any;

  constructor(private collegeService: CollegeService,
              private sessionService: SessionService,
              private studentService: StudentService) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(): void {
    this.getColleges();
    this.getStudents();
  }

  getColleges(): void {
    this.collegeService.get(this.data).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.colleges = data['data'];
      }
    });
  }

  getSessions(id): void {
    this.sessionService.get(id).subscribe(data => {
      this.sessions = data['data'];
    })
  }

  getStudents(): void {
    // this.studentService.get(this.data).subscribe(data => {
    //   console.log(data);
    // })
  }
}
